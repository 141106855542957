.App {
    font-family: sans-serif;
    text-align: center;
}

.stuevaltable{
    border: 1px solid;
    margin-left: auto;
    margin-right: auto;
}

.stuevalrow {
    border: 1px solid;
}

.stuevalheader {
    border: 1px solid;
    font-weight: bold;
}

.stuevalcol {
    border: 1px solid;
}

.centeritem {
    margin: auto;
    width: 50%;
    border: 3px solid;
    padding: 10px;
    text-align: center;
}

.centeritemnoborder {
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
}
.myheader {
    font-size: 150%;
}